import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Accordion from 'react-bootstrap/Accordion';

const FAQs = () => {
  const [faqs, setFaqs] = useState([
    {
      question: 'What is your B2B application?',
      answer: 'Our B2B application is a cloud-based platform that helps businesses of all sizes manage their sales, marketing, and customer service operations. Our application is designed to be easy to use and affordable, and it comes with a wide range of features that can help businesses improve their efficiency and productivity.',
    },
    {
      question: 'How do you bill for your B2B application?',
      answer: 'We bill for our B2B application on a subscription basis. You can choose to pay monthly, quarterly, or annually.',
    },
    {
      question: 'How do I contact your support team?',
      answer: 'You can contact our support team by email or chat 24/7. We also offer phone support during business hours.',
    },
    {
      question: 'What type of customer support do you offer?',
      answer: (
        <div>
          We offer a variety of customer support options, including:
          <ul>
            <li>Online documentation</li>
            <li>Phone support</li>
            <li>Email support</li>
          </ul>
        </div>
      ),

     
    },
    {
      question: 'Do you offer free trials?',
      answer: 'Yes, we offer a free trial of our B2B application. This allows you to try out the application and see if it is a good fit for your business.',
    },
    {
      question: 'What are your pricing options?',
      answer: 'We offer a variety of pricing plans to meet the needs of businesses of all sizes. Our plans are based on the number of users and the features that you need.',
    },
  ]);

  const [activeIndex, setActiveIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <React.Fragment>
 <section className="contact overflow-hidden" id="contact">
        <Container className="ml-4">
          <Row className="justify-content-center">
            <Col lg={12}>
              <div className="sec-hd">
                <span className="heading"></span>
                <h2 className="sec-title">Frequently Asked Questions</h2>
                <span className="heading"></span>
              </div>
            </Col>
          </Row>
          <div className="col-md-12">
    <div className="container ">

      <Accordion  >
        {faqs.map((faq, index) => (
          <Accordion.Item key={faq.question} eventKey={index.toString()}>
            <Accordion.Header onClick={() => handleAccordionClick(index)}>
            <div className=" faq-question"> {faq.question}</div>
            {/* {faq.question} */}
             
              {activeIndex === index ? (
                <i className="bi bi-chevron-up"></i>
              ) : (
                <i className="bi bi-chevron-down"></i>
              )}
            </Accordion.Header>
            <Accordion.Body>
              <div className="faq-answer">{faq.answer}</div>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
    </div>
    </Container>
      </section>
    </React.Fragment>
  );
};

export default FAQs;