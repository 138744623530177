import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { Alert, Button} from 'react-bootstrap';
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';




const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    comments: ''
  });
  const [successMessage, setSuccessMessage] = useState("");
 
  const handleSubmit = (e) => {
    e.preventDefault();
  
    // Check if all fields are filled
    if (formData.name && formData.email && formData.subject && formData.comments) {
      // Send the email using emailjs
      emailjs.send('service_ys8sl1p', 'template_93udwbq', formData, 'Lea6f1fEDHysXtM-b', {
        reply_to: formData.email
      })
        .then((response) => {
          console.log('Email sent successfully!', response.text);
          // Reset the form fields
          setSuccessMessage("Email sent successfully!");
          setFormData({
            name: '',
            email: '',
            subject: '',
            comments: ''
          });
        })
        .catch((error) => {
          console.error('Error sending email:', error);
        });
    } else {
      // Display an error message if any field is empty
      document.getElementById('error-msg').textContent = 'Please fill in all fields.';
    }
  };
  const handleDismiss = () => {
    setSuccessMessage("");
  };
 
 

  
  return (
    <React.Fragment>
      <section className="contact overflow-hidden" id="contact">
        <Container >
          <Row className="justify-content-center">
            <Col lg={12}>
              <div className="sec-hd">
                <span className="heading"></span>
                <h2 className="sec-title">Contact Us!</h2>
                <span className="heading"></span>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center">
            <Col lg={8}>
              <div className="contact-box">
                <div className="custom-form mt-4">
               < form method="post" name="myForm" onSubmit={handleSubmit}>
                    <p id="error-msg" style={{ opacity: 1 }}> </p>
                    <div id="simple-msg"></div>
                    <Row>
                      <Col lg={12}>
                        <div className="form-group">
                          <input name="name" id="name" type="text"
                            className="form-control contact-form input"placeholder= "Your name" 
                            
                            value={formData.name}
                            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                            
                            
                            
                            />
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div className="form-group">
                          <input name="email" id="email" type="email"
                            className="form-control contact-form" placeholder="Your email" 
                            
                            value={formData.email}
                            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                            />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <div className="form-group">
                          <input type="text" className="form-control contact-form" id="subject"
                            placeholder="Your Subject.."
                            
                            value={formData.subject}
                            onChange={(e) => setFormData({ ...formData, subject: e.target.value })}
                            
                            
                            />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <div className="form-group">
                          <textarea name="comments" id="comments" rows="6"
                            className="form-control contact-form h-auto"
                            placeholder="Your message..."
                            
                            value={formData.comments}
                            onChange={(e) => setFormData({ ...formData, comments: e.target.value })}
                            
                            
                            ></textarea>
                        </div>
                      </Col>
                    </Row>
                    <Row className="justify-content-center">
                      <div className="col-lg-6">
                        <div className="text-center">
                          <input type="submit" id="submit" name="send"
                            className="submitBnt btn btn-rounded bg-gradiant" value="Send Message"/>
                        </div>
                      </div>
                    </Row>
                  </form>
                 {
                     successMessage && (
                      <Alert variant="success" className="mt-3" dismissible onClose={handleDismiss}>
            {successMessage}
            <text variant="outline-success" size="sm" onClick={handleDismiss}>
          
            </text>
          </Alert>
                 )}
    
                </div>
              </div>
            </Col>
          </Row>

          <Row className="my-5 py-4 justify-content-center text-center">
            <Col md={4}>
              <div>
                <i className="mdi mdi-google-maps f-50 text-primary"></i>
                <h5 className="mb-1">Location</h5>
                <p className="f-14 mb-0 text-muted"> Mina Mall,jemo,Addis Ababa, 6th floor</p>
              </div>
            </Col>
            <Col md={4}>
              <div className="mt-4 mt-lg-0">
                <i className="mdi mdi-email f-50 text-primary"></i>
                <h5 className="mb-1">Email</h5>
                <p className="f-14 mb-0 text-muted">Email:ghiooninfo@gmail.com</p>
              </div>
             

            </Col>
            <Col md={4}>
              <div className="mt-4 mt-lg-0">
                <i className="mdi mdi-phone f-50 text-primary"></i>
                <h5 className="mb-1">Phone</h5>
                <p className="f-14 mb-0 text-muted">+011 3 699626</p>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="position-relative">
          <div className="contact-map">
            <iframe className="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3941.0343899547684!2d38.71892361091466!3d8.968978291052794!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b87d728536d7b%3A0xde1f55499d07d56c!2sMina%20Mall%2C%20Jemo%2C%20Addis%20Ababa!5e0!3m2!1sen!2set!4v1701425816542!5m2!1sen!2set"
              width="100%" height="450" title="myFrame" style={{ border: 0 }} allowFullScreen="" loading="lazy"></iframe>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

export default Contact;