import React, { useEffect, useState, useRef } from 'react'
import { Navigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import {
    Navbar,
    Nav,
    Container,
    Row,
    Col,
} from 'react-bootstrap';


// import Image
import logoLight from "../assets/images/merglog1.png";
import logoDark from "../assets/images/merglog1.png";

const Navbar1 = () => {

    const [activeItem, setActiveItem] = useState('Home');

    const [navClass, setnavClass] = useState("");
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);


    // navbar Scroll

    useEffect(() => {
        window.addEventListener("scroll", scrollNavigation, true);
        return () => {
            window.removeEventListener("scroll", scrollNavigation, true);
        };
    }, []);

    function scrollNavigation() {
        const scrollPosition = window.scrollY;
        const windowHeight = window.innerHeight;
        var scrollup = document.documentElement.scrollTop;
        if (scrollup > 50) {
            setnavClass("nav-sticky");
        } else {
            setnavClass("");
        } 
        const activeSection = sections.find(section => {
          const element = document.getElementById(section.id);
          if (element) {
            const elementTop = element.offsetTop;
            const elementHeight = element.offsetHeight;
            return scrollPosition >= elementTop - windowHeight / 2 && scrollPosition < elementTop + elementHeight - windowHeight / 2;
          }
          return false;
        });
      
        if (activeSection) {
          setActiveItem(activeSection.name);
        }
      
    }

    // toggle
    const navMenuRef = useRef(null);
    const toggleNavMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
    };

    const handleNavItemClick = () => {
        // Close the mobile menu when a menu item is clicked
        setMobileMenuOpen(false);
    };

    const sections = [
        { id: 'home', name: 'Home' },
        { id: 'work', name: 'Work' },
        { id: 'features', name: 'Features' },
        { id: 'app', name: 'Application' },
        { id: 'contact', name: 'Contact' },
        
        // Add more sections here
      ];
    return (
        <React.Fragment>
            <div data-bs-spy="scroll" data-bs-target="#navbar" data-bs-offset="61" data-bs-smooth-scroll="true" className="scrollspy-example-2">
               

                <nav className={`navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-light ${navClass}`}
                    id="navbar">
                    <Container fluid>
                        <Navbar.Brand href="#" className="logo text-uppercase">
                            <img src={logoLight} className="logo-light" alt="" height="30" />
                            <img src={logoDark} className="logo-dark" alt="" height="30" />
                        </Navbar.Brand>

                        <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarCollapse"
                            aria-controls="navbarCollapse"
                            aria-expanded={isMobileMenuOpen ? 'true' : 'false'}
                            aria-label="Toggle navigation"
                            onClick={toggleNavMenu}
                        >
                            <span className="mdi mdi-menu"></span>
                        </button>
                        <div className={`collapse navbar-collapse ${isMobileMenuOpen ? 'show' : ''}`} id="navbarCollapse">
                            <ul className="navbar-nav ms-auto" id="navbar-navlist">
                                {/* Nav.Link components with handleNavItemClick */}
                                <li className={activeItem === 'Home' ? 'active' : 'nav-item'} onClick={() => { setActiveItem('Home'); handleNavItemClick(); }}>
                                    <Nav.Link href="#home">Home</Nav.Link>
                                </li>
                                
                                {/* <li className={activeItem === 'Team' ? 'active' : 'nav-item'} onClick={() => setActiveItem('Team')}>
                                    <Nav.Link href="#team"> Team</Nav.Link>
                                </li> */}
                                <li className={activeItem === 'Features' ? 'active' : 'nav-item'} onClick={() =>{ setActiveItem('Features'); handleNavItemClick(); }}>
                                    <Nav.Link href="#features">Features</Nav.Link>
                                </li>
                                {/* <li className={activeItem === 'Pricing' ? 'active' : 'nav-item'} onClick={() => setActiveItem('Pricing')}>
                                    <Nav.Link href="#pricing">Pricing</Nav.Link>
                                </li> */}
                          
                                <li className={activeItem === 'Work' ? 'active' : 'nav-item'} onClick={() =>{ setActiveItem('Work'); handleNavItemClick(); }}>
                                    <Nav.Link href="#work">Benefit</Nav.Link>
                                </li>
                                <li className={activeItem === 'Application' ? 'active' : 'nav-item'} onClick={() => {setActiveItem('Application'); handleNavItemClick(); }}>
                                    <Nav.Link href="#app">Application</Nav.Link>
                                </li>
                                <li className={activeItem === 'Contact' ? 'active' : 'nav-item'} onClick={() => {setActiveItem('Contact'); handleNavItemClick(); }}>
                                    <Nav.Link href="#contact">Contact</Nav.Link>
                                </li>
                                {/* <li className={activeItem === 'FAQs' ? 'active' : 'nav-item'} onClick={() => setActiveItem('FAQs')}> */}
                                    {/* <Nav.Link href="/FAQs">Faqs</Nav.Link> */}
                                    {/* <Link to="/FAQs">Faqs</Link> */}
                                    {/* <Navigate to="/FAQs" replace={true} /> */}
                                {/* </li> */}
     
                            </ul>
                            
                            {/* <div className="ms-auto">
                                <Link to="/FAQs" className="btn bg-gradiant">Login</Link>
                            </div> */}
                        </div>
                    </Container>
                </nav>
            </div>
        </React.Fragment>
    )
}

export default Navbar1;

 {/* <section className="tagline d-none d-md-block">
                    <Container fluid>
                        <Row className="align-items-center">
                            <Col md={6}>
                                <div className="d-flex">
                                    <div className="phone">
                                        <i className="mdi mdi-phone"></i>  +011 3699626
                                    </div>
                                    <div className="email ms-3">
                                        <Link to="mailto:#" className="text-dark">
                                            <i className="mdi mdi-email-open-outline"></i> ghioon.customers@gmail.com
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <ul className="top_socials d-flex list-unstyled justify-content-end mb-0">
                                    <li className="entry">
                                        <Link to="https://www.facebook.com/gihon.shop" target="_blank">
                                            <i className="bx bxl-facebook-circle"></i>
                                        </Link>
                                    </li>
                                    <li className="entry">
                                        <Link to="https://t.me/gihon_shop" target="_blank">
                                            <i className="bx bxl-telegram"></i>
                                        </Link>
                                    </li>
                                    {/* <li className="entry">
                                        <Link to="#">
                                            <i className="bx bxl-instagram"></i>
                                        </Link>
                                    </li> */}
                        //             </ul>
                        //             </Col>
                        //         </Row>
                        //         <div className="clear"></div>
                        //     </Container>
                        // </section> */}