import React, { useState } from 'react';
import { Container, Row, Col,Card, Image  } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';


const PageNotFound = () => {
 

  return (
    <React.Fragment>
 <section className="contact overflow-hidden" id="contact">
        <Container style={{ backgroundColor: '#f1f1f1', padding: '20px' }} className="ml-4">
          <Row className="justify-content-center">
          <Col md="auto">
        <h1 style={{ fontSize: '80px', fontWeight: 700, textAlign: 'center' }}>404</h1>
        <h2 style={{ fontSize: '32px', textAlign: 'center', marginBottom: '20px' }}>Page Not Found</h2>
        <p style={{ textAlign: 'center' }}>
          The page you are looking for does not exist or has been moved.
        </p>
        <a href="/" className="btn btn-primary">Return to Homepage</a>
      </Col>
      </Row>
    </Container>
      </section>
    </React.Fragment>
  );
};

export default PageNotFound;