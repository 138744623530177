import React from 'react'
import {
  Container,
  Row,
  Col,
  Badge
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Autoplay } from "swiper";


// import images
import img1 from "../../assets/images/testi/ss/img15.jpeg"
import img2 from "../../assets/images/testi/ss/img16.jpeg"
import img3 from "../../assets/images/testi/ss/img17.jpeg"
import img4 from "../../assets/images/testi/ss/img11.png"
import img5 from "../../assets/images/testi/ss/img18.jpeg"
import img6 from "../../assets/images/testi/ss/img19.jpeg"
import img9 from "../../assets/images/testi/ss/img20.jpeg"
// import img7 from "../../assets/images/testi/ss/img22.png"
// import img8 from "../../assets/images/testi/ss/img99.png"
import appStore from "../../assets/images/img-appstore.png"
import googleplay from "../../assets/images/img-googleplay.png"


const section = () => {
  return (
    <React.Fragment>
      <section className="home-2 home-slider" id="home">
        <div className="box">
          <div className="wave -one"></div>
          <div className="wave -two"></div>
          <div className="wave -three"></div>
        </div>
        <Container>
          <Row className="align-items-center justify-content-between">
            <Col md={6}>
              <div className="home-heading">
                <Badge bg="soft-primary" className="rounded-pill mb-3">Ghioon Technology</Badge>
                <h3>Connecting Sellers, Unleashing Business Potential</h3>
                <p className="home-title"></p>
                <p className="text-muted">Our platform is revolutionizing the way sellers connect with sellers, creating endless opportunities for collaboration and growth. Seamlessly bridging the gap between businesses, our app fosters a vibrant marketplace where sellers can expand their networks, share resources, and forge valuable partnerships. </p>
                <div className="d-flex mt-4" style={{ justifyContent: 'center', alignItems: 'center' }}>
  <div className="googleplay" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Link to="https://play.google.com/store/apps/details?id=com.ghioon.ghioon_b2b" target="_blank">
      <img src={googleplay} alt="" className="img-fluid ms-3" />
    </Link>
  </div>
</div>

              </div>
            </Col>
            <Col md={6}>
              <div style={{marginTop: '73px '}} className="swiper swiper-container homeSwiper  ">
                <div className="swiper-wrapper">
                  <Swiper
                    loop={true}
                    effect={"coverflow"}
                    spaceBetween={50}
                    centeredSlides={true}
                    slidesPerView={2}
                    speed={5000}
                    autoplay={{
                      delay: 500,
                      disableOnInteraction: false,
                    }}
                    modules={[EffectCoverflow, Autoplay]}
                    className="mySwiper"
                  >
                    <SwiperSlide className="swiper-slide border-radius">
                      <div className="app-screenshot-item text-center d-flex justify-content-center">
                        <div className="app-screenshot-overlayer">
                          <Link className="mfp-image img-fluid" to='# 'title=""></Link>
                        </div>
                        <div className="screenshot-img">
                          <Link to='#' className="lightbox img-fluid">
                            <img src={img1} alt="" className='img-fluid' />
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className="swiper-slide border-radius">
                      <div className="app-screenshot-item text-center d-flex justify-content-center">
                        <div className="app-screenshot-overlayer">
                          <Link className="mfp-image img-fluid" to='#' title=""></Link>
                        </div>
                        <div className="screenshot-img">
                          <Link to='#' className="lightbox img-fluid">
                            <img src={img2} alt="" className="img-fluid" />
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className="swiper-slide border-radius">
                      <div className="app-screenshot-item text-center d-flex justify-content-center">
                        <div className="app-screenshot-overlayer">
                          <Link className="mfp-image img-fluid" to='#' title=""></Link>
                        </div>
                        <div className="screenshot-img">
                          <Link to='#' className="lightbox img-fluid">
                            <img src={img3} alt="" className="img-fluid" />
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className="swiper-slide border-radius">
                      <div className="app-screenshot-item text-center d-flex justify-content-center">
                        <div className="app-screenshot-overlayer">
                          <Link className="mfp-image img-fluid" to='#' title=""></Link>
                        </div>
                        <div className="screenshot-img">
                          <Link to='#' className="lightbox img-fluid">
                            <img src={img4} alt="" className="img-fluid" />
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className="swiper-slide border-radius">
                      <div className="app-screenshot-item text-center d-flex justify-content-center">
                        <div className="app-screenshot-overlayer">
                          <Link className="mfp-image img-fluid" to='#' title=""></Link>
                        </div>
                        <div className="screenshot-img">
                          <Link to='#'className="lightbox img-fluid">
                            <img src={img5} alt="" className="img-fluid" />
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className="swiper-slide border-radius">
                      <div className="app-screenshot-item text-center d-flex justify-content-center">
                        <div className="app-screenshot-overlayer">
                          <Link className="mfp-image img-fluid" to='#' title=""></Link>
                        </div>
                        <div className="screenshot-img">
                          <Link to='#' className="lightbox img-fluid">
                            <img src={img6} alt="" className="img-fluid" />
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className="swiper-slide border-radius">
                      <div className="app-screenshot-item text-center d-flex justify-content-center">
                        <div className="app-screenshot-overlayer">
                          <Link className="mfp-image img-fluid" to='#' title=""></Link>
                        </div>
                        <div className="screenshot-img">
                          <Link to='#' className="lightbox img-fluid">
                            <img src={img9} alt="" className="img-fluid" />
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide>
                    {/* <SwiperSlide className="swiper-slide border-radius">
                      <div className="app-screenshot-item text-center d-flex justify-content-center">
                        <div className="app-screenshot-overlayer">
                          <Link className="mfp-image img-fluid" to={img7} title=""></Link>
                        </div>
                        <div className="screenshot-img">
                          <Link to={img7} className="lightbox img-fluid">
                            <img src={img7} alt="" className="img-fluid" />
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide> */}
                    {/* <SwiperSlide className="swiper-slide border-radius">
                      <div className="app-screenshot-item text-center d-flex justify-content-center">
                        <div className="app-screenshot-overlayer">
                          <Link className="mfp-image img-fluid" to={img7} title=""></Link>
                        </div>
                        <div className="screenshot-img">
                          <Link to={img7} className="lightbox img-fluid">
                            <img src={img7} alt="" className="img-fluid" />
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide> */}
                    {/* <SwiperSlide className="swiper-slide border-radius">
                      <div className="app-screenshot-item text-center d-flex justify-content-center">
                        <div className="app-screenshot-overlayer">
                          <Link className="mfp-image img-fluid" to='#' title=""></Link>
                        </div>
                        <div className="screenshot-img">
                          <Link to='#' className="lightbox img-fluid">
                            <img src={img8} alt="" className="img-fluid" />
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide> */}
                  </Swiper>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Container fluid>
        <Row>
          <div className="mobile">
            <Link to="#about">
              <span className="phone">
                <i className="mdi mdi-cellphone"></i>
              </span>
            </Link>
          </div>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default section;