import React from 'react'
import { Route, Routes } from "react-router-dom";
import Layout from '../layout/index';
import frequently from '../layout/frequently';
import Error from '../layout/error';
import authProtectedRoutes from './allRoutes';
import PageNotFound from '../components/PageNotFound'


const Index = () => {
    return (
        <React.Fragment>
                <Routes>
        {authProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              route.path === '/FAQs' ? (
                <frequently>{route.nnn}</frequently>
              ) : route.path === '*' ? (
                <frequently>
                  <PageNotFound />
                </frequently>
              ) : (
                <Layout>{route.component}</Layout>
              )
            }
            key={idx}
            exact={true}
          />
        ))}
           {/* <Route path="*" element={<Error />} /> */}
      </Routes>
        </React.Fragment>
    );
};

export default Index;