import React from 'react'
import Navbar1 from '../../layout/Navbar';
import Section from './section';

const layout2 = () => {
  return (
    <React.Fragment>
    <Navbar1 />
    <Section />
  </React.Fragment>
  )
}

export default layout2;