import React from 'react'
// import Navbar1 from '../../layout/Navbar';
import Footer from '../../layout/Footer';
import Section from './section';



const errorlayout = () => {
  return (
    <React.Fragment>
      {/* <Navbar1 /> */}
      <Section />
      <Footer />
    
    </React.Fragment>
  )
}

export default errorlayout;