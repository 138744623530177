import React from "react";
// import AboutUs from '../components/AboutUs'
import Features from '../components/Features'
import Work from "../components/work";
// import Team from '../components/Team'
import Application from '../components/Application'

import Contact from '../components/Contact'
import Footer from "./Footer";


const Layout = ({ children }) => {
    return (
        <React.Fragment>
            {children}
            {/* <AboutUs /> */}
            <Features />
            <Work />
           
            <Application />
         
            <Contact />
           {/* <Team /> */}
            <Footer />
        </React.Fragment>
    )
}

export default Layout;