import React from 'react';
import {
  Container,
  Row,
  Col,
  Image
} from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/core';
import { Link } from 'react-router-dom';


import features from "../assets/images/appdownload2.png";
// import images
import phoneFream from "../assets/images/testi/phone-fream.png"
import img1 from "../assets/images/testi/ss/img1.png";
import img2 from "../assets/images/testi/ss/img2.png";
import img3 from "../assets/images/testi/ss/img3.png";
import img4 from "../assets/images/testi/ss/img4.png";
import img5 from "../assets/images/testi/ss/img5.png";
import img6 from "../assets/images/testi/ss/img6.png";
import img7 from "../assets/images/testi/ss/img7.png";
import img8 from "../assets/images/testi/ss/img8.png";
import appStore from "../assets/images/img-appstore.png"
import googleplay from "../assets/images/img-googleplay.png"

const Application = () => {

  return (
    <React.Fragment>
      <section className="section app-slider bg-light" id="app">
      <section className="section cta bg-light" id="cta">
        <Container className="position-relative">
        <Row className="justify-content-center">
            <Col lg={12}>
              <div className="sec-hd">
                <span className="heading"></span>
                <h2 className="sec-title">Download  our app </h2>
                <span className="heading"></span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={6} lg={10}>
              <div className="py-5">
                <h1 className="display-4">Download Our Seller App.</h1>
                <p className="text-muted mt-3 f-18">
                 Download the seller App from App store or Play store
                </p>
                <div className="d-flex mt-4" style={{ justifyContent: 'center', alignItems: 'center' }}>
                  {/* <div className="app-store">
                  <Link to="https://www.apple.com/app-store/" target="_blank"  >
                      <img src={appStore} alt="" className="img-fluid" />
                    </Link>
                  </div> */}
                  <div className="googleplay" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Link to="https://play.google.com/store/apps/details?id=com.ghioon.ghioon_b2b" target="_blank">
      <img src={googleplay} alt="" className="img-fluid ms-3" />
    </Link>
  </div>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="cta-phone-image">
              <img src={features} className="img-fluid"alt="" />
              </div>
              <style jsx>{`
        @media (min-width: 992px) {
          .cta-phone-image {
            display: none;
          }
        }
      `}</style>
            </Col>
          </Row>
        </Container>
      </section>
      </section>
    </React.Fragment>
  )
}

export default Application;