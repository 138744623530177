import React from 'react'
import Navbar1 from '../../layout/navbar2';
import Footer from '../../layout/Footer2';
import Section from './section';



const faq1 = () => {
  return (
    <React.Fragment>
      <Navbar1 />
      <Section />
      <Footer />
    
    </React.Fragment>
  )
}

export default faq1;